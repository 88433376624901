import { CatalogChannelItem, SupplierChannelItem } from '@supy/channel-items';
import { IdType, OutletData, SimpleUser, SkeletonObjectType } from '@supy/common';
import { Country } from '@supy/countries';
import { BaseItem } from '@supy/inventory';
import { Packaging } from '@supy/packaging';
import { RetailerItemCategory } from '@supy/retailer-item-categories';

export class CategoryOrder {
  readonly id: string;
  readonly number: string;
  readonly category: RetailerItemCategory;
  readonly 'category.id': string;
  readonly branch: SkeletonObjectType;
  readonly 'branch.id': string;
  readonly createdAt: Date;
  readonly deliveryDate: Date;
  readonly total: number;
  readonly status: CategoryOrderStatus;
  readonly user: SimpleUser;
  readonly comment: string;
  readonly orderedPackages: CategoryOrderPackaging[];
  readonly country?: Country;
  readonly subtotal?: number;
  readonly outlet?: OutletData;

  constructor(args: CategoryOrder) {
    this.id = args.id;
    this.number = args.number;
    this.category = args.category;
    this.branch = args.branch;
    this.createdAt = args.createdAt;
    this.deliveryDate = args.deliveryDate;
    this.subtotal = args.subtotal;
    this.status = args.status;
    this.user = args.user;
    this.comment = args.comment;
    this.orderedPackages = args.orderedPackages;
    this.country = args.country;
    this.outlet = args.outlet;
    this.total = args.total;
  }
}

export type BranchCategoryOrder = Omit<CategoryOrder, 'category' | 'user'>;

export class CategoryOrderPackaging {
  readonly quantity: number;
  readonly price: number;
  readonly channelItems: CatalogChannelItem[];
  readonly selectedRetailerItem: BaseItem;
  readonly selectedChannelItem: CatalogChannelItem;
  readonly selectedPackaging: Packaging;
  readonly category: RetailerItemCategory;
  readonly categoryOrder: CategoryOrderSnapshot;
}

export interface CategoryOrderBasketItem {
  readonly channelItem: SupplierChannelItem;
  readonly packaging: IdType;
  readonly quantity: number;
}

export interface CategoryOrderBasket {
  readonly items?: CategoryOrderBasketItem[];
  readonly categoryId?: string;
}

export class CategoryOrderSnapshot {
  readonly id: string;
  readonly number: string;
}

export enum CategoryOrderStatus {
  pending = 'pending',
  processed = 'processed',
}

export enum CategoryOrderFilter {
  all = 'all',
  pending = 'pending',
  processed = 'processed',
  discarded = 'discarded',
}

export enum CategoryViewByFilter {
  branch = 'branch',
  category = 'category',
}
